import { ReactElement } from "react";
import Form from "./icons/Form";
import Free from "./icons/Free";
import Justice from "./icons/justice";
import style from "./style.module.scss";
import ScrollToTop from "@components/shared/scrollToTop";

export default function Parkinson(): ReactElement {
    const list = [
        {
            title: "Fill Out Our Quick Form",
            body: "Within hours a member of our team will contact you to start the process.",
            icon: <Form />,
        },
        {
            title: "Get Your Free Legal Consultation",
            body: "We will walk you through all the information you need to determine your damages and eligibility.  All of your questions will be answered in detail.",
            icon: <Free />,
        },
        {
            title: "Get the Justice You Deserve",
            body: "You'll be matched with a team of experienced attorneys who have won millions in damages for Roundup lawsuits. They'll be there to support you every step of the way.",
            icon: <Justice />,
        },
    ];
    return (
        <>
            <div className="flex w-full">
                <h2
                    className="mt-[100px] mx-auto text-2xl md:text-4xl font-bold "
                    style={{
                        color: "#0a6938",
                    }}
                >
                    Legal Help Advisor Process
                </h2>
            </div>
            <div className={style.wrapper}>
                {list.map((item, index) => {
                    return (
                        <div key={item.title} className={style.item}>
                            <div className={style.index}>{index + 1}</div>
                            <div>
                                <div>{item.icon}</div>
                                <h3>{item.title}</h3>
                            </div>
                            <p>{item.body}</p>
                        </div>
                    );
                })}
            </div>
            <div className="flex w-full mb-[100px]">
                <ScrollToTop
                    colors={{
                        bg: "#ff7d06",
                        text: "#fff",
                    }}
                    className="mx-auto rounded"
                >
                    Get My Free Lawsuit Case Review
                </ScrollToTop>
            </div>
        </>
    );
}
