import React from "react";

function Justice() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="none"
            viewBox="0 0 60 60"
        >
            <mask
                id="mask0_0_75"
                style={{ maskType: "alpha" }}
                width="60"
                height="60"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H60V60H0z"></path>
            </mask>
            <g fill="#356E3F" mask="url(#mask0_0_75)">
                <path d="M40.815 49.5H4.184c-.71 0-1.185.514-1.185 1.286v6.428c0 .772.474 1.286 1.185 1.286h36.63c.71 0 1.185-.514 1.185-1.286v-6.428c0-.772-.593-1.286-1.185-1.286z"></path>
                <path d="M59.644 52.444L33.519 26.319l3.681-3.681 1.781 1.781c.237.237.594.356.831.356.238 0 .594-.119.832-.356l4.631-4.631c.237-.238.356-.475.356-.832 0-.356-.119-.593-.356-.83L29.006 1.855a1.148 1.148 0 00-1.662 0l-4.632 4.632a1.148 1.148 0 000 1.662l1.781 1.781-13.062 13.063-1.78-1.782a1.148 1.148 0 00-1.663 0l-4.632 4.632a1.148 1.148 0 000 1.662l16.27 16.27c.236.236.593.355.83.355.238 0 .594-.118.832-.356l4.631-4.631c.237-.237.356-.475.356-.831 0-.357-.119-.594-.356-.832l-1.781-1.78 3.681-3.682 26.125 26.125c.237.237.593.356.83.356.238 0 .594-.119.832-.356l4.038-4.038c.237-.237.356-.475.356-.831s-.12-.594-.357-.831z"></path>
            </g>
        </svg>
    );
}

export default Justice;
