import React from "react";

function Slowness() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="none"
            viewBox="0 0 60 60"
        >
            <mask
                id="mask0_0_72"
                style={{ maskType: "alpha" }}
                width="60"
                height="60"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H60V60H0z"></path>
            </mask>
            <g mask="url(#mask0_0_72)">
                <path
                    fill="#356E3F"
                    fillRule="evenodd"
                    d="M42.72 35.463a2.284 2.284 0 002.281-2.282 2.284 2.284 0 00-2.28-2.28 2.284 2.284 0 00-2.281 2.28 2.284 2.284 0 002.28 2.282zm10.754 2.693H40.541l-4.434-4.974 4.434-4.975h12.933v9.95zm.463-12.724V14.094a1.39 1.39 0 00-1.388-1.388h-2.66V7.732a1.39 1.39 0 00-1.387-1.388h-6.466a1.387 1.387 0 100 2.776h5.079v3.586h-7.759c-.559 0-1.06.334-1.278.85-.18.43-16.212 39.093-15.955 39.482.258.389.69.62 1.156.62h29.27a1.39 1.39 0 001.388-1.388V40.933h.925a1.39 1.39 0 001.388-1.388V26.821a1.39 1.39 0 00-1.388-1.388l-.925-.001zM38.697 3.86a1.386 1.386 0 00-1.818.74l-20.94 49.723a1.378 1.378 0 00-.007 1.062 1.38 1.38 0 001.284.865c.56 0 1.063-.333 1.28-.85l20.94-49.723a1.388 1.388 0 00-.74-1.817zm-8.002 10.773s.135-.918-.123-1.307a1.384 1.384 0 00-1.157-.62H8.287c-.972 0-1.761-.791-1.761-1.762 0-1.032.791-1.824 1.765-1.824h23.804a1.386 1.386 0 001.157-2.156 1.385 1.385 0 00-1.157-.62H8.291a4.54 4.54 0 00-4.541 4.533v35.607c0 3.956 3.216 7.173 7.17 7.173h2.418c.56 0 1.062-.333 1.278-.85l16.078-38.174z"
                    clipRule="evenodd"
                ></path>
            </g>
        </svg>
    );
}

export default Slowness;
