import React from "react";

function Form() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="none"
            viewBox="0 0 60 60"
        >
            <mask
                id="mask0_0_69"
                style={{ maskType: "alpha" }}
                width="60"
                height="60"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H60V60H0z"></path>
            </mask>
            <g mask="url(#mask0_0_69)">
                <path
                    fill="#356E3F"
                    d="M23.968 0v3.571h-4.613V10h21.29V3.57h-4.612V0H23.968zM8 6.428V60h44V6.428h-8.517v5c0 .749-.676 1.429-1.419 1.43H17.935c-.743-.001-1.419-.681-1.419-1.43v-5H8zm14.793 12.835a1.443 1.443 0 011.397.78 1.485 1.485 0 01-.222 1.608l-6.387 7.143c-.51.58-1.515.613-2.062.067l-3.548-3.572c-.546-.521-.552-1.492-.023-2.03.53-.54 1.494-.544 2.019 0l2.483 2.5 5.39-6.005c.238-.28.588-.46.953-.491zm4.236 4.308c.044-.003.089-.003.133 0h19.871c.75-.011 1.442.673 1.442 1.428 0 .755-.692 1.44-1.442 1.429h-19.87c-.744.035-1.452-.614-1.487-1.362-.035-.748.61-1.46 1.353-1.495zm-4.236 7.834a1.443 1.443 0 011.397.782 1.485 1.485 0 01-.222 1.607l-6.387 7.143c-.51.58-1.515.613-2.062.067l-3.548-3.572c-.546-.521-.552-1.492-.023-2.03.53-.54 1.494-.544 2.019 0l2.483 2.5 5.39-6.005c.238-.28.588-.46.953-.491zm4.236 4.309c.044-.002.089-.002.133 0h19.871c.75-.011 1.442.673 1.442 1.428 0 .755-.692 1.44-1.442 1.429h-19.87c-.744.035-1.452-.614-1.487-1.362-.035-.748.61-1.46 1.353-1.495zm-4.236 7.835a1.441 1.441 0 011.397.78 1.484 1.484 0 01-.222 1.608l-6.387 7.143c-.51.58-1.515.613-2.062.067l-3.548-3.572c-.546-.522-.552-1.492-.023-2.03.53-.54 1.494-.544 2.019 0l2.483 2.499 5.39-6.005c.238-.279.588-.459.953-.49zm4.236 4.307c.044-.002.089-.002.133 0h19.871c.75-.01 1.442.674 1.442 1.429s-.692 1.44-1.442 1.428h-19.87c-.744.036-1.452-.613-1.487-1.36-.035-.75.61-1.462 1.353-1.497z"
                ></path>
            </g>
        </svg>
    );
}

export default Form;
